import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const Wrapper = styled('div')(({ label, theme }) => {
  const labelMaxLength = label?.length >= 32

  return css`
    margin-bottom: ${selectTheme('spacingY.6xl')({ theme })};

    ${media.up('5xl')} {
      margin-bottom: ${selectTheme('spacingY.8xl')({ theme })};
    }

    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;

    label {
      padding-right: ${selectTheme('spacingX.6xl')({ theme })};

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    textarea {
      margin-top: ${labelMaxLength ? '60px' : '32px'};
      height: ${labelMaxLength ? '32px' : '64px'};

      ${media.up('5xl')} {
        margin-bottom: ${selectTheme('spacingY.8xl')({ theme })};
      }
    }
  `
})
